.dynamic-delete-button {
  margin: 0 8px;
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}

.subform-row {
  display: flex;
  flex-direction: row;
}