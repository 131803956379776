.modal-body {
  display: flex;
  flex-direction: column;
  width: 50vw;
}

.close-button {
  margin-bottom: 1rem;
}

.modal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}