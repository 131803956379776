.logo-dark {
  color: #2B303A;
  font-family: 'Fredericka';
  font-size: 60px;
}

.logo-light {
  color: #e9ecf5ff;
  font-family: 'Fredericka';
  font-size: 60px;
}

.logo-highlight {
  color: #FEB95F;
}

.logo-small {
  font-size: 30px;
}