.background {
  align-items: center;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url(../../assets/LoginBackground.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  height: 100vh;
  justify-content: center;
  width: 100vw;
}

.login-card {
  display: flex;
  flex-direction: column;
}
