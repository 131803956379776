@font-face {
  font-family: 'OpenSans';
  src: url("./assets/fonts/OpenSans-Regular.ttf") format('truetype');
}

@font-face {
  font-family: 'OpenSans';
  src: url("./assets/fonts/OpenSans-SemiBold.ttf") format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'OpenSans';
  src: url("./assets/fonts/OpenSans-Bold.ttf") format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Fredericka';
  src: url("./assets/fonts/FrederickatheGreat-Regular.ttf") format('truetype');
}