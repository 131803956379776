.custom-ordered {
  padding-left: 0rem;
  list-style: none;
  counter-reset: my-awesome-counter;
}

.custom-list-item {
  display: inline-block;
  padding-left: 0.5rem;
}

.custom-list-number{
  counter-increment: my-awesome-counter;
  margin-bottom: 0.5rem;
}

.custom-list-number::before {
  content: counter(my-awesome-counter);
  background: #56A3A6;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  display: inline-block;
  line-height: 2rem;
  color: white;
  text-align: center;
}